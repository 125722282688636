<template>
  <PageContent class="notifications-content">
    <NotificationsHost view-type="page" />
  </PageContent>
</template>

<script lang="ts">
import { NotificationsHost, PageContent } from '@bd/components'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    PageContent,
    NotificationsHost,
  },
})
</script>

<style lang="scss" scoped>
:deep(.notifications-content) {
  @include breakpoint-down(md) {
    background-color: $white;
    height: calc((var(--vh) * 100) - #{$mobile-navigation-height}) !important;
  }
}
</style>
