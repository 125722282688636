
import { NotificationsHost, PageContent } from '@bd/components'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    PageContent,
    NotificationsHost,
  },
})
